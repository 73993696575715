@import 'node_modules/angular2-toaster/toaster';
/* You can add global styles to this file, and also import other style files */
:root{
    --color-theme: #FF6800;
    --color-gold: #FFE607;
    --color-white: #FFFFFF;
    --color-associate: #ECEDF0;
    --color-active: #050C31;
    --color-active2: #232C4F;
    --color-active-dark: #0D174D;
    --color-text: #000000;
    --font-general: "Noto Sans JP";
    --font-alternate: "Noto Sans";
}
html, body, *{
    font-family: var(--font-general);
    font-family: var(--font-alternate);
    font-family: sans-serif;
}
.top5{
	top: 15px;
}
.toast:not(.show){
	display: flex !important;
}
.toast-container ::ng-deep .toast {
	display: flex !important;
}
.invalid-feedback{
	display: block;
}
// html::-webkit-scrollbar, body::-webkit-scrollbar {
// 	width: 5px;
// }
/* Track */
html::-webkit-scrollbar-track, body::-webkit-scrollbar-track {
	box-shadow: inset 0 0 1px 1px var(--color-active);
	border-radius: 0;
}

/* Handle */
html::-webkit-scrollbar-thumb, body::-webkit-scrollbar-thumb {
	background: var(--color-theme);
	border-radius: 0;
}

/* Handle on hover */
html::-webkit-scrollbar-thumb:hover, body::-webkit-scrollbar-thumb:hover {
	background: var(--color-theme);
}
a {
    text-decoration: none !important;
	outline: 0;
	color: var(--theme-color);
    transition: all ease .4s;
	cursor: pointer;
}
p {
    margin: 0;
    padding: 0;
    font-style: normal;
    font-variant: normal;
    font-weight: normal;
    font-size: 15px;
    line-height: 2;
}
li{
    line-height: 2;
}

.btn-theme {
	background-color: var(--color-active);
	border: 1px solid var(--color-active);
	box-shadow: 0px 0px 1px 0px var(--color-active-dark);
	color: var(--color-white);
	outline: none;
	padding: 10px 15px;
	border-radius: 4px;
	font-weight: 600;
	font-size: 18px;
	transition: all 0.2s ease-in-out 0.1s;
}
.btn-theme:hover {
	background-color: var(--color-theme);
	color: var(--color-white);
	border: 1px solid var(--color-active);
	box-shadow: 0px 0px 1px 0px var(--color-active2);
	outline: none;
	transition: all 0.2s ease-in-out 0.1s;
	border-radius: 2px;
}
input::placeholder, textarea::placeholder, select::placeholder {
	color: var(--color-theme);
	background-color: var(--color-active);
}
input::-internal-autofill-selected {
    appearance: menulist-button;
    background-image: none !important;
    background-color: var(--color-active) !important;
    color: var(--color-theme) !important;
}
select:focus {
	border-color: var(--colorHeading);
	outline: none;
	box-shadow: none;
}

input[type=radio] {
    visibility: hidden;
    opacity: 0;
    display: inline-block;
    vertical-align: middle;
    width: 0;
    height: 0;
    display: none;
}
label {
    font-size: 14px;
    font-weight: 700;
    color: var(--color-active-dark);
    margin-bottom: 10px;
    line-height: 1;
}
input[type=radio]~label {
    position: relative;
    padding-left: 25px;
    cursor: pointer;
    display: block;
    line-height: 1.3;
    margin-bottom: 11px;
}
input[type=radio]~label:after, input[type=radio]~label:before {
    content: "";
    position: absolute;
    border-radius: 100%;
    transition: all .2s ease;
}
input[type=radio]~label:after {
    width: 10px;
    height: 10px;
    top: 4px;
    left: 3px;
    opacity: 0;
    transform: scale(0);
}
input[type=radio]~label::before {
    top: 1px;
    left: 0;
    width: 16px;
    height: 16px;
    border: 1px solid var(--color-active);
    background: var(--color-theme);
}
input[type=radio]:checked~label::before {
    border-color: var(--color-active-dark);
}
input[type=radio]:checked~label:after {
    opacity: 1;
    background-color: var(--color-active);
    transform: scale(1);
}
